import React from 'react'

import Navbar from './navbar.js'


const Header = ({ siteTitle }) => (
  <Navbar />
)


export default Header

import React from 'react'

const Footer = () => (
  <footer className="footer has-bg-img">
    <div className="hero is-small">
      <div className="hero-body has-text-centered has-text-white">
        Made with ❤ using Bulma and Gatsby
      </div>
    </div>
  </footer>
)

export default Footer

import React from 'react';
import { Link } from 'gatsby'
import styles from './navbar.module.scss'
class Navbar extends React.Component {

  state = { showMenu: false }

  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu
    })
  }

  render() {
    const menuActive = this.state.showMenu ? 'is-active' : '';
    const burgerActive = this.state.showMenu ? 'is-active' : '';
      return (
  <nav className={`navbar is-fixed-top ${styles.navbar}`}>
    <div className="navbar-brand">
      <Link className="navbar-item" to="/">
        Planet and Sky&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </Link>
      <a role="button" className={`navbar-burger burger ${burgerActive}`} onClick={this.toggleMenu} aria-label="menu" aria-expanded="false">
        <span></span>
        <span></span>
        <span></span>
      </a>
    </div>

    <div className={`navbar-menu ${menuActive} ${styles.navbar}`} >
      <div className="navbar-start">
        <Link className="navbar-item navbar-link has-dropdown is-hoverable music" to="/music" onClick={this.toggleMenu}>
          The Music
          <div className="navbar-dropdown">
            <Link className="navbar-item" to="/songs/something_is_dreaming" onClick={this.toggleMenu}>
              Something is Dreaming
            </Link>
            <Link className="navbar-item" to="/songs/round" onClick={this.toggleMenu}>
              Round
            </Link>
            <Link className="navbar-item" to="/songs/travelers" onClick={this.toggleMenu}>
              Travelers
            </Link>
            <Link className="navbar-item" to="/songs/eons_of_joy" onClick={this.toggleMenu}>
              Eons of Joy
            </Link>
            <Link className="navbar-item" to="/songs/such_is_love" onClick={this.toggleMenu}>
              Such is Love
            </Link>
            <Link className="navbar-item" to="/songs/beautiful_life" onClick={this.toggleMenu}>
              Beautiful Life
            </Link>
            <Link className="navbar-item" to="/songs/planet_my_love" onClick={this.toggleMenu}>
              Planet My Love
            </Link>
            <Link className="navbar-item" to="/songs/empty_world" onClick={this.toggleMenu}>
              Empty World
            </Link>
            <Link className="navbar-item" to="/songs/wake_up" onClick={this.toggleMenu}>
              Wake Up!
            </Link>
          </div>
        </Link>
        <Link className="navbar-item navbar-link has-dropdown is-hoverable" to="/podcast" onClick={this.toggleMenu}>
          The Podcast
          <div className="navbar-dropdown">
            <Link className="navbar-item" to="/episodes/episode1" onClick={this.toggleMenu}>
              Episode 1: 'cross the Glistening Sands
            </Link>
            <Link className="navbar-item" to="/episodes/episode2" onClick={this.toggleMenu}>
              Episode 2: Lovers World and Sky
            </Link>
            <Link className="navbar-item" to="/episodes/episode3" onClick={this.toggleMenu}>
              Episode 3: One World Grows Larger
            </Link>
            <Link className="navbar-item" to="/episodes/episode4" onClick={this.toggleMenu}>
              Episode 4: Gonna flex my mantle…
            </Link>
            <Link className="navbar-item" to="/episodes/episode5" onClick={this.toggleMenu}>
              Episode 5: She flows into my valleys
            </Link>
            <Link className="navbar-item" to="/episodes/episode6" onClick={this.toggleMenu}>
              Episode 6: Now it grows colder
            </Link>
            <Link className="navbar-item" to="/episodes/episode7" onClick={this.toggleMenu}>
              Episode 7: Your rivers run empty
            </Link>
            <Link className="navbar-item" to="/episodes/episode8" onClick={this.toggleMenu}>
              Episode 8: Planet adrift
            </Link>
            <Link className="navbar-item" to="/episodes/episode9" onClick={this.toggleMenu}>
              Episode 9: Greet the dawn!
            </Link>
          </div>
        </Link>
        <Link className="navbar-item navbar-link is-hoverable is-arrowless" to="/people" onClick={this.toggleMenu}>
          The People
        </Link>
        <Link className="navbar-item navbar-link is-hoverable is-arrowless" to="/links" onClick={this.toggleMenu}>
          Related Links
        </Link>
      </div>
    </div>
  </nav>)
  }
};

export default Navbar;